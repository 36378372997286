import {forkJoin} from "rxjs";
import {StorageKeys} from "./share/enums/storage.enum";

export function userSelfDetails(selfCall: any, blockChainCall: any, storageService: any, envType: any, callback: any){
  let forkJoinDetails: any = {
    selfDetails: selfCall.getSelfDetails()
  }
  if(envType === 'DAMS'){
    forkJoinDetails.documentVault = blockChainCall.getDocumentvaultStatus();
  }else if(envType === 'SDEX'){
    forkJoinDetails.blockchain = blockChainCall.getBlockchainData();
  }
  forkJoin(
    forkJoinDetails
  ).subscribe((res: any) => {
    if(!res?.selfDetails?.data){
      return callback(null);
    }
    if(res?.selfDetails?.data?.enrollmentType != 'Admin'){
      eSignBlockChainVerify(storageService, res?.selfDetails?.data, res.blockchain);
    }
    storageService.setDataToLocalStorage(
      StorageKeys.SELF_DETAILS,
      res?.selfDetails?.data
    );
   let  selfDetail = {...res?.selfDetails?.data, documentVault: res.documentVault?.data}
    callback(selfDetail)
  })
}

let hasSigned = false;
let hasESignPermission = false;
let hasGenerated = false;
let hasBlockChainPermission = false;
let assignedCompany: any = {};
 function eSignBlockChainVerify(storageService: any, selfDetails: any = {}, blockchainDetails: any = {}) {
  let presentCompany = storageService.getDataFromLocalStorage('presentCompany');
  selfDetails.company = presentCompany || selfDetails?.assignedTo?.[0]?.company?._id;
  selfDetails.hideActionsNoEsign = false;
  selfDetails.hideActionsNoBlockchain = false;

  for(let assigned of (selfDetails?.assignedTo || [])){
    if ((assigned.company._id === selfDetails.company) && selfDetails.company) {
      assignedCompany = assigned;
      selfDetails.presentRoles = assigned.roles || [];
      selfDetails.primary = assigned.primary;
      selfDetails.isAdmin = assigned.isAdmin;
    }
  }
  checkAccess(selfDetails, blockchainDetails)

  if(hasESignPermission && !hasSigned){
    selfDetails.hideActionsNoEsign = true;
  }
  if(hasBlockChainPermission && !hasGenerated){
    selfDetails.hideActionsNoBlockchain = true;
  }
  return selfDetails
}

function checkAccess(selfDetails: any, blockchainDetails: any = {}) {
  if ((assignedCompany.primary || assignedCompany.isAdmin)) {
    hasESignPermission = true;
    hasSigned = !!selfDetails?.eSignSelfLink;
    hasGenerated = blockchainDetails?.data?.keyGenerated;
    hasBlockChainPermission = true;
  } else {
    eSignBlockAccess(assignedCompany,selfDetails,blockchainDetails)
  }
}

function eSignBlockAccess(assignedCompany: any, selfDetails: any, blockchainDetails: any = {}) {
  assignedCompany.roles?.forEach((x: any) => {
    if (x.permissions.filter((y: any) => y.module == 'e-sign-details').length > 0) {
      hasESignPermission = true;
      hasSigned = !!selfDetails?.eSignSelfLink;
    }
    for (let permission of x.permissions) {
      if (permission.module === 'package') {
        if (permission.enabled || permission.subPermissions.checker) {
          hasGenerated = blockchainDetails?.data?.keyGenerated;
          hasBlockChainPermission = true;
        }
      }
    }
  })
}

export function globalPreferences(commonService: any, module: string, fileType: any, callback: any){
  commonService.globalPreferences((err: any, preferences: any) => {
    if(module === 'fileExtensions' && preferences?.[module]){
      if(fileType === 'returnObject'){
        callback(null, preferences?.[module]);
        return;
      }
      let exts: any[] = [];
      for(let i =0; i < preferences[module].allowedFileTypes?.length; i++){
        if((fileType && preferences[module].allowedFileTypes[i].fileType === fileType) || (!fileType)){
          exts = exts.concat(preferences[module].allowedFileTypes[i].availableExtensions);
        }
      }
      callback(null, exts);
      return;
    }
    callback(null, preferences?.[module]);
  })
}
